/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
// @import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

.modal-transparent {
  --background: none transparent !important;
}

.toast-center {
  text-align: center;
}

ion-header {
  ion-toolbar {
    --background: var(--ion-color-light);
    ion-buttons {
      ion-back-button {
        color: var(--ion-color-primary) !important;
      }
    }
    ion-title {
      text-align: center;
      color: var(--ion-color-primary) !important;
    }
  }
}

ion-item {
  ion-label {
    color: var(--ion-color-primary) !important;
    white-space: nowrap; // fix for ionic 7
  }
  ion-label:hover {
    cursor: pointer !important;
  }
}

ion-tab-bar {
  --background: var(--ion-color-light);
}

button {
  color: var(--ion-color-light-contrast);
}

[hidden] {
  display: none !important;
}

button.alert-danger {
  color: var(--ion-color-danger) !important;
}
button.alert-success {
  color: var(--ion-color-success) !important;
}

.error-message {
  padding-top: 4pt;
  padding-right: 8pt;
  color: var(--ion-color-danger) !important;
  font-size: 0.8rem;
}

.full-screen-modal {
  --width: 100%;
  --height: 100%;
}

ion-calendar {
  .availableDate {
    p {
      color: var(--ion-color-success) !important;
      font-weight: bold;
    }
    small {
      color: var(--ion-color-success) !important;
    }
  }
}

ion-loading.map-loading {
  color: var(--ion-color-warning);
  --spinner-color: var(--ion-color-warning);
}
