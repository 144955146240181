// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #003062;
  --ion-color-primary-rgb: 0, 48, 98;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #002a56;
  --ion-color-primary-tint: #1a4572;

  /** secondary **/
  --ion-color-secondary: #00b1eb;
  --ion-color-secondary-rgb: 0, 177, 235;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #009ccf;
  --ion-color-secondary-tint: #1ab9ed;

  /** tertiary **/
  --ion-color-tertiary: #00b1eb;
  --ion-color-tertiary-rgb: 0, 177, 235;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #009ccf;
  --ion-color-tertiary-tint: #1ab9ed;

  /** success **/
  --ion-color-success: #2e8b57;
  --ion-color-success-rgb: 46, 139, 87;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #287a4d;
  --ion-color-success-tint: #439768;

  /** warning **/
  --ion-color-warning: #ff8c00;
  --ion-color-warning-rgb: 255, 140, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e07b00;
  --ion-color-warning-tint: #ff981a;

  /** danger **/
  --ion-color-danger: #b22222;
  --ion-color-danger-rgb: 178, 34, 34;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #9d1e1e;
  --ion-color-danger-tint: #ba3838;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #d3d3d3;
  --ion-color-medium-rgb: 211, 211, 211;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #bababa;
  --ion-color-medium-tint: #d7d7d7;

  /** light **/
  --ion-color-light: #f5f5f5;
  --ion-color-light-rgb: 245, 245, 245;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8d8d8;
  --ion-color-light-tint: #f6f6f6;

  /** custom **/

  --ion-color-text: #003062;
  --ion-color-text-rgb: 0, 48, 98;
  --ion-color-text-contrast: #ffffff;
  --ion-color-text-contrast-rgb: 255, 255, 255;
  --ion-color-text-shade: #002a56;
  --ion-color-text-tint: #1a4572;

  --ion-color-orange: #ff8c00;
  --ion-color-orange-rgb: 255, 140, 0;
  --ion-color-orange-contrast: #000000;
  --ion-color-orange-contrast-rgb: 0, 0, 0;
  --ion-color-orange-shade: #e07b00;
  --ion-color-orange-tint: #ff981a;

  --ion-color-contrast: #f5f5f5;
  --ion-color-contrast-rgb: 245, 245, 245;
  --ion-color-contrast-contrast: #000000;
  --ion-color-contrast-contrast-rgb: 0, 0, 0;
  --ion-color-contrast-shade: #d8d8d8;
  --ion-color-contrast-tint: #f6f6f6;

  --ion-color-icon: #f5f5f5;
  --ion-color-icon-rgb: 245, 245, 245;
  --ion-color-icon-contrast: #000000;
  --ion-color-icon-contrast-rgb: 0, 0, 0;
  --ion-color-icon-shade: #d8d8d8;
  --ion-color-icon-tint: #f6f6f6;

  /*  Material Design color palette (full) downloaded from
 *  http://htmlcolorcodes.com/color-charts/
 *
 *  Check out our powerful color picker
 *  http://htmlcolorcodes.com/color-picker/
 *
 *  Want to learn more about colors in SCSS?
 *  http://htmlcolorcodes.com/tutorials/
 *
 *  - - - - - - - - - - - - - - - - - - 
 *  Credits:
 *
 *  Original color palette by
 *  https://www.google.com/design/spec/style/color.html
 *
 *  - - - - - - - - - - - - - - - - - -
 *  How to use:
 *  
 *  Include this file with your other .scss files:
 *
 *  @import "material_colors_full";
 *  
 *  and use the color variables in your stylesheets:
 *	
 *  .class {
 *    color: $red-500;
 *  }
 *
 *  - - - - - - - - - - - - - - - - - -
 *  Note:
 *
 *  $color and $color-500 are the same, simply there
 *  for your convenience.
 *
 *  Variables with numbers prefixed by 'a' are accent colors
 */

  /* Red */
  --materialize-color-red: #f44336;
  --materialize-color-red-50: #ffebee;
  --materialize-color-red-100: #ffcdd2;
  --materialize-color-red-200: #ef9a9a;
  --materialize-color-red-300: #e57373;
  --materialize-color-red-400: #ef5350;
  --materialize-color-red-500: #f44336;
  --materialize-color-red-600: #e53935;
  --materialize-color-red-700: #d32f2f;
  --materialize-color-red-800: #c62828;
  --materialize-color-red-900: #b71c1c;
  --materialize-color-red-a100: #ff8a80;
  --materialize-color-red-a200: #ff5252;
  --materialize-color-red-a400: #ff1744;
  --materialize-color-red-a700: #d50000;

  /* Pink */
  --materialize-color-pink: #e91e63;
  --materialize-color-pink-50: #fce4ec;
  --materialize-color-pink-100: #f8bbd0;
  --materialize-color-pink-200: #f48fb1;
  --materialize-color-pink-300: #f06292;
  --materialize-color-pink-400: #ec407a;
  --materialize-color-pink-500: #e91e63;
  --materialize-color-pink-600: #d81b60;
  --materialize-color-pink-700: #c2185b;
  --materialize-color-pink-800: #ad1457;
  --materialize-color-pink-900: #880e4f;
  --materialize-color-pink-a100: #ff80ab;
  --materialize-color-pink-a200: #ff4081;
  --materialize-color-pink-a400: #f50057;
  --materialize-color-pink-a700: #c51162;

  /* Purple */
  --materialize-color-purple: #9c27b0;
  --materialize-color-purple-50: #f3e5f5;
  --materialize-color-purple-100: #e1bee7;
  --materialize-color-purple-200: #ce93d8;
  --materialize-color-purple-300: #ba68c8;
  --materialize-color-purple-400: #ab47bc;
  --materialize-color-purple-500: #9c27b0;
  --materialize-color-purple-600: #8e24aa;
  --materialize-color-purple-700: #7b1fa2;
  --materialize-color-purple-800: #6a1b9a;
  --materialize-color-purple-900: #4a148c;
  --materialize-color-purple-a100: #ea80fc;
  --materialize-color-purple-a200: #e040fb;
  --materialize-color-purple-a400: #d500f9;
  --materialize-color-purple-a700: #aa00ff;

  /* Deep Purple */
  --materialize-color-deep-purple: #673ab7;
  --materialize-color-deep-purple-50: #ede7f6;
  --materialize-color-deep-purple-100: #d1c4e9;
  --materialize-color-deep-purple-200: #b39ddb;
  --materialize-color-deep-purple-300: #9575cd;
  --materialize-color-deep-purple-400: #7e57c2;
  --materialize-color-deep-purple-500: #673ab7;
  --materialize-color-deep-purple-600: #5e35b1;
  --materialize-color-deep-purple-700: #512da8;
  --materialize-color-deep-purple-800: #4527a0;
  --materialize-color-deep-purple-900: #311b92;
  --materialize-color-deep-purple-a100: #b388ff;
  --materialize-color-deep-purple-a200: #7c4dff;
  --materialize-color-deep-purple-a400: #651fff;
  --materialize-color-deep-purple-a700: #6200ea;

  /* Indigo */
  --materialize-color-indigo: #3f51b5;
  --materialize-color-indigo-50: #e8eaf6;
  --materialize-color-indigo-100: #c5cae9;
  --materialize-color-indigo-200: #9fa8da;
  --materialize-color-indigo-300: #7986cb;
  --materialize-color-indigo-400: #5c6bc0;
  --materialize-color-indigo-500: #3f51b5;
  --materialize-color-indigo-600: #3949ab;
  --materialize-color-indigo-700: #303f9f;
  --materialize-color-indigo-800: #283593;
  --materialize-color-indigo-900: #1a237e;
  --materialize-color-indigo-a100: #8c9eff;
  --materialize-color-indigo-a200: #536dfe;
  --materialize-color-indigo-a400: #3d5afe;
  --materialize-color-indigo-a700: #304ffe;

  /* Blue */
  --materialize-color-blue: #2196f3;
  --materialize-color-blue-50: #e3f2fd;
  --materialize-color-blue-100: #bbdefb;
  --materialize-color-blue-200: #90caf9;
  --materialize-color-blue-300: #64b5f6;
  --materialize-color-blue-400: #42a5f5;
  --materialize-color-blue-500: #2196f3;
  --materialize-color-blue-600: #1e88e5;
  --materialize-color-blue-700: #1976d2;
  --materialize-color-blue-800: #1565c0;
  --materialize-color-blue-900: #0d47a1;
  --materialize-color-blue-a100: #82b1ff;
  --materialize-color-blue-a200: #448aff;
  --materialize-color-blue-a400: #2979ff;
  --materialize-color-blue-a700: #2962ff;

  /* Light Blue */
  --materialize-color-light-blue: #03a9f4;
  --materialize-color-light-blue-50: #e1f5fe;
  --materialize-color-light-blue-100: #b3e5fc;
  --materialize-color-light-blue-200: #81d4fa;
  --materialize-color-light-blue-300: #4fc3f7;
  --materialize-color-light-blue-400: #29b6f6;
  --materialize-color-light-blue-500: #03a9f4;
  --materialize-color-light-blue-600: #039be5;
  --materialize-color-light-blue-700: #0288d1;
  --materialize-color-light-blue-800: #0277bd;
  --materialize-color-light-blue-900: #01579b;
  --materialize-color-light-blue-a100: #80d8ff;
  --materialize-color-light-blue-a200: #40c4ff;
  --materialize-color-light-blue-a400: #00b0ff;
  --materialize-color-light-blue-a700: #0091ea;

  /* Cyan */
  --materialize-color-cyan: #00bcd4;
  --materialize-color-cyan-50: #e0f7fa;
  --materialize-color-cyan-100: #b2ebf2;
  --materialize-color-cyan-200: #80deea;
  --materialize-color-cyan-300: #4dd0e1;
  --materialize-color-cyan-400: #26c6da;
  --materialize-color-cyan-500: #00bcd4;
  --materialize-color-cyan-600: #00acc1;
  --materialize-color-cyan-700: #0097a7;
  --materialize-color-cyan-800: #00838f;
  --materialize-color-cyan-900: #006064;
  --materialize-color-cyan-a100: #84ffff;
  --materialize-color-cyan-a200: #18ffff;
  --materialize-color-cyan-a400: #00e5ff;
  --materialize-color-cyan-a700: #00b8d4;

  /* Teal */
  --materialize-color-teal: #009688;
  --materialize-color-teal-50: #e0f2f1;
  --materialize-color-teal-100: #b2dfdb;
  --materialize-color-teal-200: #80cbc4;
  --materialize-color-teal-300: #4db6ac;
  --materialize-color-teal-400: #26a69a;
  --materialize-color-teal-500: #009688;
  --materialize-color-teal-600: #00897b;
  --materialize-color-teal-700: #00796b;
  --materialize-color-teal-800: #00695c;
  --materialize-color-teal-900: #004d40;
  --materialize-color-teal-a100: #a7ffeb;
  --materialize-color-teal-a200: #64ffda;
  --materialize-color-teal-a400: #1de9b6;
  --materialize-color-teal-a700: #00bfa5;

  /* Green */
  --materialize-color-green: #4caf50;
  --materialize-color-green-50: #e8f5e9;
  --materialize-color-green-100: #c8e6c9;
  --materialize-color-green-200: #a5d6a7;
  --materialize-color-green-300: #81c784;
  --materialize-color-green-400: #66bb6a;
  --materialize-color-green-500: #4caf50;
  --materialize-color-green-600: #43a047;
  --materialize-color-green-700: #388e3c;
  --materialize-color-green-800: #2e7d32;
  --materialize-color-green-900: #1b5e20;
  --materialize-color-green-a100: #b9f6ca;
  --materialize-color-green-a200: #69f0ae;
  --materialize-color-green-a400: #00e676;
  --materialize-color-green-a700: #00c853;

  /* Light Green */
  --materialize-color-light-green: #8bc34a;
  --materialize-color-light-green-50: #f1f8e9;
  --materialize-color-light-green-100: #dcedc8;
  --materialize-color-light-green-200: #c5e1a5;
  --materialize-color-light-green-300: #aed581;
  --materialize-color-light-green-400: #9ccc65;
  --materialize-color-light-green-500: #8bc34a;
  --materialize-color-light-green-600: #7cb342;
  --materialize-color-light-green-700: #689f38;
  --materialize-color-light-green-800: #558b2f;
  --materialize-color-light-green-900: #33691e;
  --materialize-color-light-green-a100: #ccff90;
  --materialize-color-light-green-a200: #b2ff59;
  --materialize-color-light-green-a400: #76ff03;
  --materialize-color-light-green-a700: #64dd17;

  /* Lime */
  --materialize-color-lime: #cddc39;
  --materialize-color-lime-50: #f9fbe7;
  --materialize-color-lime-100: #f0f4c3;
  --materialize-color-lime-200: #e6ee9c;
  --materialize-color-lime-300: #dce775;
  --materialize-color-lime-400: #d4e157;
  --materialize-color-lime-500: #cddc39;
  --materialize-color-lime-600: #c0ca33;
  --materialize-color-lime-700: #afb42b;
  --materialize-color-lime-800: #9e9d24;
  --materialize-color-lime-900: #827717;
  --materialize-color-lime-a100: #f4ff81;
  --materialize-color-lime-a200: #eeff41;
  --materialize-color-lime-a400: #c6ff00;
  --materialize-color-lime-a700: #aeea00;

  /* Yellow */
  --materialize-color-yellow: #ffeb3b;
  --materialize-color-yellow-50: #fffde7;
  --materialize-color-yellow-100: #fff9c4;
  --materialize-color-yellow-200: #fff59d;
  --materialize-color-yellow-300: #fff176;
  --materialize-color-yellow-400: #ffee58;
  --materialize-color-yellow-500: #ffeb3b;
  --materialize-color-yellow-600: #fdd835;
  --materialize-color-yellow-700: #fbc02d;
  --materialize-color-yellow-800: #f9a825;
  --materialize-color-yellow-900: #f57f17;
  --materialize-color-yellow-a100: #ffff8d;
  --materialize-color-yellow-a200: #ffff00;
  --materialize-color-yellow-a400: #ffea00;
  --materialize-color-yellow-a700: #ffd600;

  /* Amber */
  --materialize-color-amber: #ffc107;
  --materialize-color-amber-50: #fff8e1;
  --materialize-color-amber-100: #ffecb3;
  --materialize-color-amber-200: #ffe082;
  --materialize-color-amber-300: #ffd54f;
  --materialize-color-amber-400: #ffca28;
  --materialize-color-amber-500: #ffc107;
  --materialize-color-amber-600: #ffb300;
  --materialize-color-amber-700: #ffa000;
  --materialize-color-amber-800: #ff8f00;
  --materialize-color-amber-900: #ff6f00;
  --materialize-color-amber-a100: #ffe57f;
  --materialize-color-amber-a200: #ffd740;
  --materialize-color-amber-a400: #ffc400;
  --materialize-color-amber-a700: #ffab00;

  /* Orange */
  --materialize-color-orange: #ff9800;
  --materialize-color-orange-50: #fff3e0;
  --materialize-color-orange-100: #ffe0b2;
  --materialize-color-orange-200: #ffcc80;
  --materialize-color-orange-300: #ffb74d;
  --materialize-color-orange-400: #ffa726;
  --materialize-color-orange-500: #ff9800;
  --materialize-color-orange-600: #fb8c00;
  --materialize-color-orange-700: #f57c00;
  --materialize-color-orange-800: #ef6c00;
  --materialize-color-orange-900: #e65100;
  --materialize-color-orange-a100: #ffd180;
  --materialize-color-orange-a200: #ffab40;
  --materialize-color-orange-a400: #ff9100;
  --materialize-color-orange-a700: #ff6d00;

  /* Deep Orange */
  --materialize-color-deep-orange: #ff5722;
  --materialize-color-deep-orange-50: #fbe9e7;
  --materialize-color-deep-orange-100: #ffccbc;
  --materialize-color-deep-orange-200: #ffab91;
  --materialize-color-deep-orange-300: #ff8a65;
  --materialize-color-deep-orange-400: #ff7043;
  --materialize-color-deep-orange-500: #ff5722;
  --materialize-color-deep-orange-600: #f4511e;
  --materialize-color-deep-orange-700: #e64a19;
  --materialize-color-deep-orange-800: #d84315;
  --materialize-color-deep-orange-900: #bf360c;
  --materialize-color-deep-orange-a100: #ff9e80;
  --materialize-color-deep-orange-a200: #ff6e40;
  --materialize-color-deep-orange-a400: #ff3d00;
  --materialize-color-deep-orange-a700: #dd2c00;

  /* Brown */
  --materialize-color-brown: #795548;
  --materialize-color-brown-50: #efebe9;
  --materialize-color-brown-100: #d7ccc8;
  --materialize-color-brown-200: #bcaaa4;
  --materialize-color-brown-300: #a1887f;
  --materialize-color-brown-400: #8d6e63;
  --materialize-color-brown-500: #795548;
  --materialize-color-brown-600: #6d4c41;
  --materialize-color-brown-700: #5d4037;
  --materialize-color-brown-800: #4e342e;
  --materialize-color-brown-900: #3e2723;

  /* Grey */
  --materialize-color-grey: #9e9e9e;
  --materialize-color-grey-50: #fafafa;
  --materialize-color-grey-100: #f5f5f5;
  --materialize-color-grey-200: #eeeeee;
  --materialize-color-grey-300: #e0e0e0;
  --materialize-color-grey-400: #bdbdbd;
  --materialize-color-grey-500: #9e9e9e;
  --materialize-color-grey-600: #757575;
  --materialize-color-grey-700: #616161;
  --materialize-color-grey-800: #424242;
  --materialize-color-grey-900: #212121;

  /* Blue Grey */
  --materialize-color-blue-grey: #607d8b;
  --materialize-color-blue-grey-50: #eceff1;
  --materialize-color-blue-grey-100: #cfd8dc;
  --materialize-color-blue-grey-200: #b0bec5;
  --materialize-color-blue-grey-300: #90a4ae;
  --materialize-color-blue-grey-400: #78909c;
  --materialize-color-blue-grey-500: #607d8b;
  --materialize-color-blue-grey-600: #546e7a;
  --materialize-color-blue-grey-700: #455a64;
  --materialize-color-blue-grey-800: #37474f;
  --materialize-color-blue-grey-900: #263238;

  /* White / Black */
  --materialize-color-white: #ffffff;
  --materialize-color-black: #000000;

  /*  Want more? Check our our other color palettes at
 *  http://htmlcolorcodes.com/color-charts/
 */
}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #428cff;
//     --ion-color-primary-rgb: 66, 140, 255;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255, 255, 255;
//     --ion-color-primary-shade: #3a7be0;
//     --ion-color-primary-tint: #5598ff;

//     --ion-color-secondary: #50c8ff;
//     --ion-color-secondary-rgb: 80, 200, 255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255, 255, 255;
//     --ion-color-secondary-shade: #46b0e0;
//     --ion-color-secondary-tint: #62ceff;

//     --ion-color-tertiary: #6a64ff;
//     --ion-color-tertiary-rgb: 106, 100, 255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255, 255, 255;
//     --ion-color-tertiary-shade: #5d58e0;
//     --ion-color-tertiary-tint: #7974ff;

//     --ion-color-success: #2fdf75;
//     --ion-color-success-rgb: 47, 223, 117;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0, 0, 0;
//     --ion-color-success-shade: #29c467;
//     --ion-color-success-tint: #44e283;

//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255, 213, 52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0, 0, 0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;

//     --ion-color-danger: #ff4961;
//     --ion-color-danger-rgb: 255, 73, 97;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255, 255, 255;
//     --ion-color-danger-shade: #e04055;
//     --ion-color-danger-tint: #ff5b71;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244, 245, 248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0, 0, 0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #989aa2;
//     --ion-color-medium-rgb: 152, 154, 162;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0, 0, 0;
//     --ion-color-medium-shade: #86888f;
//     --ion-color-medium-tint: #a2a4ab;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34, 36, 40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255, 255, 255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #ffffff;
//     --ion-background-color-rgb: 255,255,255;
  
//     --ion-text-color: #000000;
//     --ion-text-color-rgb: 0,0,0;
  
//     --ion-color-step-50: #f2f2f2;
//     --ion-color-step-100: #e6e6e6;
//     --ion-color-step-150: #d9d9d9;
//     --ion-color-step-200: #cccccc;
//     --ion-color-step-250: #bfbfbf;
//     --ion-color-step-300: #b3b3b3;
//     --ion-color-step-350: #a6a6a6;
//     --ion-color-step-400: #999999;
//     --ion-color-step-450: #8c8c8c;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #737373;
//     --ion-color-step-600: #666666;
//     --ion-color-step-650: #595959;
//     --ion-color-step-700: #4d4d4d;
//     --ion-color-step-750: #404040;
//     --ion-color-step-800: #333333;
//     --ion-color-step-850: #262626;
//     --ion-color-step-900: #191919;
//     --ion-color-step-950: #0d0d0d;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }

//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #ffffff;
//     --ion-background-color-rgb: 255,255,255;
  
//     --ion-text-color: #000000;
//     --ion-text-color-rgb: 0,0,0;
  
//     --ion-color-step-50: #f2f2f2;
//     --ion-color-step-100: #e6e6e6;
//     --ion-color-step-150: #d9d9d9;
//     --ion-color-step-200: #cccccc;
//     --ion-color-step-250: #bfbfbf;
//     --ion-color-step-300: #b3b3b3;
//     --ion-color-step-350: #a6a6a6;
//     --ion-color-step-400: #999999;
//     --ion-color-step-450: #8c8c8c;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #737373;
//     --ion-color-step-600: #666666;
//     --ion-color-step-650: #595959;
//     --ion-color-step-700: #4d4d4d;
//     --ion-color-step-750: #404040;
//     --ion-color-step-800: #333333;
//     --ion-color-step-850: #262626;
//     --ion-color-step-900: #191919;
//     --ion-color-step-950: #0d0d0d;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }
// }

// html {
//   /*
//    * For more information on dynamic font scaling, visit the documentation:
//    * https://ionicframework.com/docs/layout/dynamic-font-scaling
//    */
//   --ion-dynamic-font: var(--ion-default-dynamic-font);
// }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../assets/fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/open-sans-v15-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/open-sans-v15-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/open-sans-v15-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
